.claimOrder {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  margin-right: 5.5rem;
  text-align: left;
  width: 637px;
}

.claimOrderTitle {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(51, 63, 92, 0.7);
  gap: 8px;
  margin-bottom: 24px;
  padding-bottom: 16px;
}

.claimOrderTitle h1 {
    margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  letter-spacing: -0.02em;

  color: #ffffff;
}

.claimOrderTitle p {
    margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  color: #d8e4fd;
}

.claimOrderChoseBox {
  position: relative;
  border-radius: 8px;
  border: 1px solid rgba(51, 63, 92, 0.6);
  margin-bottom: 16px;
  cursor: pointer;
}

.claimOrderChoseBoxWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;

}

.claimOrderChoseBoxHover {
  background: rgba(94, 98, 104, 0.2);
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.claimOrderChoseBox img {
  width: 24px;
  height: 24px;
}

.claimOrderChoseBoxMain {
    display: flex;
    flex-direction: column;
}

.claimOrderChoseBoxMain h3 {
    margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #ffffff;
}

.claimOrderChoseBoxMain p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */

  color: #d8e4fd;
  margin: 0;
}

.activeBorder {
    border: 1px solid #4689F6;
}
