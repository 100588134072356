.lds-dual-ring {
    display: inline-block;
    width: 100%;
    max-height: 48px;
    margin-bottom: 12px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    border-radius: 50%;
    border: 2px solid #2070F4;
    border-color: #2070F4 transparent #2070F4 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }