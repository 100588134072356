.searchResultsWrapper {
  margin-top: -6rem;
  min-height: 100vh;
  padding-top: 120px;
  padding-bottom: 30px;
    /* margin: 0 auto; */
    /* background: #06061e;
    border: 1px solid rgba(216, 230, 253, 0.15);
    border-radius: 12px;
    min-height: 100vh;
    margin-top: 160px;
    padding-top: 48px; */
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    
  }