.App {
  /* text-align: center; */
  max-height: 100vh;
}

.hideOverflowY {
  overflow-y: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.statusDiv {
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */
  text-align: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 44px;
}

.greenStatus {
  background: #489d5d;
  color: #ffffff;
}

.orangeStatus {
  background: #C9701C;
  color: #ffffff;
}

.darkblueStatus {
  background: rgba(99, 102, 233, 0.16);
  border: 0.5px solid rgba(175, 176, 244, 0.12);
  color: #8f91ef;
}

/* Buttons */
.blueRoundedBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 22px;
  gap: 4px;
  min-width: 135px;
  height: 48px;
  justify-content: center;
  background: #2070f4;
  border-radius: 80px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: center;
  text-transform: capitalize;
  outline: none;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.transparentRoundedBtn {
  display: flex;
  outline: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 22px;
  gap: 4px;

  height: 48px;
  min-width: 135px;
  border: 2px solid #2070f4;
  border-radius: 80px;
  background: transparent;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-align: center;
  text-transform: capitalize;

  color: #ffffff;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
