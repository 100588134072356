.authPage {
  display: flex;
  flex-direction: column;
}

.authPageCentered {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.authPageWrapper {
  display: flex;
  flex-direction: row;
  gap: 115px;
  min-height: 100vh;
  justify-content: center;
}
