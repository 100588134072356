.container {
    background-color: #1a202c;
    padding: 16px;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    margin: auto;
    color: white;
    font-family: Arial, sans-serif;
  }
  
  .toggle-container {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
  

  
  .toggle-button.active {
    background-color: #2070F4;
    color: white;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    background-color: #2d3748;
    border-radius: 8px;
    padding: 8px;
  }
  
  .icon {
    margin-right: 8px;
  }
  
  .phone-number {
    flex-grow: 1;
  }
  
  .label {
    font-size: 14px;
  }
  
  .number {
    font-size: 18px;
    font-weight: bold;
  }
  
  .phone-input {
    border: none;
    background: transparent;
    color: white;
    margin-right: 8px;
    text-align: center;
  }
  
  .phone-input::placeholder {
    color: #a0aec0;
  }
  
  .submit-button {
    background-color: #3182ce;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #63b3ed;
  }
  
  /* You may need to adjust the padding, margin, and other styles depending on your overall design. */
  