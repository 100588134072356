.mintProgress {
  display: flex;
  flex-direction: column;
  margin-top: 160px;
  width: 455px;
  text-align: left;
}

.mintProgressHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.mintProgressHeader img {
  width: 40px;
  height: 40px;
}

.mintProgressHeader h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  letter-spacing: -0.02em;
  margin: 0;
  color: #ffffff;
}

.mintProgress p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */

  color: #d8e4fd;
  margin: 0;
  margin-bottom: 40px;
}
