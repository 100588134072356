
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

@media (min-width: 768px) {
  .overlay {
      display: none;
  }
}



.homePage {
  display: flex;
  flex-direction: column;
  /* background-position: center; */
  /* min-height: 120vh;
  background-image: url("../../assets/home-page/background-img.png");
  background-repeat: no-repeat; */
  /* background-size: cover; /* Resize the background image to cover the entire container */
  /* height: 100vh; */
  align-items: center;
  /* overflow-y: hidden; */
}
.back{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: rgba(255, 255, 255, 0.06);
  position: absolute;
  width: 5%;
/* height: Hug (35px); */
margin-top: 5%;
left: 5%;
padding: 7px 12px 8px 12px;
border-radius: 32px;
}
.bottom {
  /* width: 1440px */
/* height: 344px */
position: relative;
margin-top: 10%;
border: 1px 0px 0px 0px;
/* background: linear-gradient(0deg, #293047, #293047); */

border-top: 1px solid rgba(41, 48, 71, 1);
border-bottom: blur(16.29px);
width: 100%;
min-height: 10%;
height:80%;
display: flex;
  flex-direction: row;
}
.bottomBackgroundGradient {
  position: absolute;
width: 100%;
height: 60vh;
min-height: 200px;
/* left: calc(50% - 162px/2); */
/* bottom: -80%; */

background: linear-gradient(180deg, rgba(82, 147, 255, 0.12) 0%, rgba(17, 17, 35, 0.12) 80.03%);
/* filter: blur(16.29px); */
}

.box {
  display: flex;
  flex-direction: row;
  justify-items: start;
  width: 33%;
/* height: Hug (122px) */
margin: 2%;
gap: 16px

}
.cpr1-footer {
  position: static;
  left: 0;
  right: 0;
  bottom: 2%;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: flex-end; */
}  


.copyright{
  /* display: flex;
  justify-content: center; */
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}
.box img {
  margin-top: -19%;
}
.box-heading{
  font-family: Arial, Helvetica, sans-serif;
font-size: 18px;
font-weight: 600;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);

}
.box-text{
  font-family: Arial, Helvetica, sans-serif;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: rgba(216, 228, 253, 1);

}
.back:hover {
  cursor: pointer;
}
.back-text {
  font-family: Arial, Helvetica, sans-serif;
font-size: 14px;
font-weight: 600;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: rgba(255, 255, 255, 1);


}
.homePageBackgroundGradient {
  position: absolute;
width: 162px;
height: 486px;
left: calc(50% - 162px/2);
top: -94px;

background: linear-gradient(180.21deg, rgba(255, 255, 255, 0.48) -185.76%, rgba(255, 255, 255, 0) 92.72%);
filter: blur(16.29px);
}

.homePageTitle {
  /* background: linear-gradient(180deg, #ffffff -37.82%, #9ec1fa 119.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; */
  margin-top: 150px;
}

.homePageTitle img {
  width: 48px;
  height: 48px;
  margin-bottom: 24px;
}

.homePageTitle h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  text-align: center;
  letter-spacing: -0.01em;
  margin: 0;
  color: #ffffff;
  margin-bottom: 8px;
}

.homePageTitle h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 78px;
  line-height: 78px;
  /* identical to box height, or 100% */
  text-align: center;
  letter-spacing: -0.04em;
  margin: 0;
}

.homePageTitle p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */
  margin: 0;
  text-align: center;
  margin-bottom: 24px;
  color: #d8e4fd;
}

.homePageInputWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-height: 72px;
  border-radius: 6px;
}

.homePageInputWrapper p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  color: #D8E4FD;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.homePageInputWrapper p img {
  width: 20px;
  height: 20px;
}

/* Bottom Image */

.lower {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lower .lower_arc {
  width: 100vw;
}

.iphone_bottom {
  position: fixed;
  bottom: -10px;
}

.iphone_bottom img {
  height: 40vh;
  border-radius: 1.7rem 1.7rem 0 0;
  /* box-shadow: 0 0 0.9rem white; */
}

/* mudit yadav css code */

.hp-bottom{
  margin-top: 12rem;
  width: 100vw;
  height: 40vh;
  min-height: 200px;
  background: linear-gradient(180deg, rgba(82, 147, 255, 0.12) 0%, rgba(17, 17, 35, 0.12) 80.03%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.hp-row-box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.hp-bottom .box{
  /* border: 2px solid white;
  width: 24rem;
  height: 10rem; */
  /* above is test code */
  display: flex;
  flex-direction: row;

}

.hp-bottom .box-img{
  /* border: 2px solid white;
  width: 2rem;
  height: 2rem; */
  /* above is test code */
  margin-top: 2rem;
  padding-top: 5px;
}

.hp-bottom .box-text{
  /* border: 2px solid white;
  width: 20rem;
  height: 10rem; */
  /* above is test code */
  margin-top: 2rem;
}

.hp-bottom .text-heading{
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 5px;
}

.hp-bottom .text-content{
  color: #D8E4FD;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.hp-learn{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 1rem;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #5293FF;
}

.smallimg{
  width: 20px !important;
  height: 20px !important;
}