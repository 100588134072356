.searchResultsTable2 {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */
    
    letter-spacing: -0.01em;
    
    color: #FFFFFF;
    margin-top: 24px;
    gap: 24px;
    text-align: left;
  }
  
  .searchResultsTableCol2 {
    /* border: 1px solid rgba(216, 230, 253, 0.08); */
    border-radius: 8px;
    /* background: rgba(216, 230, 253, 0.08); */
    gap: 24px;
    
  }
  .cart_show{
    margin-left: -20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: max-content;
    margin-top: 7%;
  }
  .cartCheckout {
    margin-top: 3%;
    width: 100%;
/* height: 48px */
/* top: 329px; */
/* left: 647px; */
padding: 16px 0px 16px 0px;
border-radius: 40px;
background: rgba(32, 112, 244, 1);
font-family: Arial, Helvetica, sans-serif;
font-size: 20px;
font-weight: 600;
line-height: 16px;
letter-spacing: 0em;
text-align: center;
color: rgba(255, 255, 255, 1);
border: none;


  }
  .each {
    gap: 100px;
  }